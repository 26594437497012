import { useAtom } from 'jotai'
import React from 'react'
import { styled } from 'styled-components'

import { Button } from '../Button'
import { isLiveAtom, playerControllerAtom } from '../Player/state'


const RedCircle = styled.div<{ $isLive: boolean }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme, $isLive }) => ($isLive ? theme.palette.red[500] : theme.palette.grey[500])};
    margin-right: 4px;
`

export const LiveButton = () => {
    const [playerController] = useAtom(playerControllerAtom)
    const [isLive] = useAtom(isLiveAtom)
    return (
        <Button
            variant="transparent"
            onClick={playerController.seekToLive}
        >
            <RedCircle $isLive={isLive} />
            LIVE
        </Button>
    )
}
