import { createIcon } from './createIcon'


export const ExclamationIcon = createIcon({
    d: 'm1.951.167.742 29.66h5.365L8.835.167H1.95zM.5 42.177c0 2.632 2.092 4.656 4.792 4.656 2.901 0 4.96-2.024 4.96-4.656 0-2.767-2.059-4.758-4.96-4.758-2.7 0-4.792 1.99-4.792 4.758z',
    defaultProps: {
        color: 'currentColor',
    },
    displayName: 'ExclamationIcon',
    viewBox: '0 0 11 47',
})
