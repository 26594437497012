import ReactGA from 'react-ga4'
import { Player, util } from 'shaka-player'

import { analytics } from '../analytics/Analytics'
import {
    AUTO_QUALITY_TITLE,
    channelNameAtom,
    currentQualityAtom,
    isBufferingAtom,
    isLiveAtom,
    isMutedAtom,
    isOsdVisibleAtom,
    isPlayingAtom,
    loadSourceErrorAtom,
    playerAudioAtom,
    playerControllerAtom,
    qualitiesAtom,
    seekRangeAtom,
    volumeAtom,
} from '../components/Player/state'
import { clamp, isCorrectLanguage, isValidQuality, notEmptyFilter } from '../helpers/utils'
import { store } from '../store'
import { PlayerController } from '../types/controller'
import { LangCode } from '../types/enum'
import { AnalyticsConversionStatus, LiveAnalyticMetricType } from '../types/events'
import { PlayerError, PlayerState, ShakaAdaptationEvent, ShakaBufferingEvent } from '../types/shaka'

import { AutoplayHandler } from './autoplayHandler'
import { isAbortError, isAutoplayNotAllowedError } from './utils'


const AUTOPLAY = true
const NOT_LIVE_THRESHOLD_MS = 15_000
const BUFFER_BEHIND = 5

// idle, playing, pause

interface EventListener {
    event: string;
    callback: (event: any) => void;
}

interface ErrorItem {
    code: number
    dataCode?: number
    timestamp: number
}

export class ShakaEngine implements PlayerController {
    private shaka: Player
    private playPromise: Promise<void> | null = null
    private updateTimeTimer: number | null = null
    private listeners: EventListener[] = []
    private playerState: PlayerState = 'idle'
    private isFirstPlay = true
    private errorsHistory: ErrorItem[] = []
    private readonly autoplayHandler = new AutoplayHandler(this)
    /**
     * When user paused the video, we should not set isLive to true until they press LIVE button
     * When bufferBehind is emptied and user unpauses the video, we should set isLive to true
     */
    private forceIsNotLive: number | null = null
    // private volumeBeforeMute:
    // private unloadEvents:

    constructor(public readonly videoElement: HTMLVideoElement) {
        this.shaka = new Player()
        this.init()
        this.subscribeEvents()

        // TODO: Add cleanup
        this.shaka.addEventListener('buffering', this.handleBuffering)

        store.set(playerControllerAtom, this)
    }

    private subscribeEvents() {
        // const engine = this
        this.listeners = [
            {
                callback: () => {
                    this.handlePause()
                },
                event: 'pause',
            },
            {
                callback: () => {
                    this.handlePlay()
                },
                event: 'play',
            },
            {
                callback: () => {
                    this.handleQualitiesChange()
                },
                event: 'loadeddata',
            },
        ]

        this.listeners.forEach((listener) => {
            this.videoElement.addEventListener(listener.event, listener.callback)
        })

        // TODO: Toto mozeme pouzit na zobrazenie aka kvalita je momentalne v Auto
        // tj. Auto (1080p)
        this.shaka.addEventListener('adaptation', function (event) {
            analytics.currentSourceQuality = (event as ShakaAdaptationEvent).newTrack.height
        })

        // tento event mozeme pouzit na zobrazenie dostupnych kvalit
        this.shaka.addEventListener('trackschanged', () => {
            this.handleQualitiesChange()
        })

        // @ts-expect-error
        this.shaka.addEventListener('error', this.handlePlaybackError)
    }

    private async init() {
        await this.shaka.attach(this.videoElement)
        this.shaka.configure({
            streaming: {
                bufferingGoal: 2,
                rebufferingGoal: 2,
                lowLatencyMode: true,
                stallEnabled: false,
                playbackRateControl: true
            },
            manifest: {
                dash: {
                    ignoreMinBufferTime: true,
                    clockSync: true
                },
                hls: {
                    minBufferTime: 2
                }
            }
        });

        // Volume which was set in previous session
        const prevVolume = store.get(volumeAtom)
        this.changeVolume(prevVolume)

        const prevMuted = store.get(isMutedAtom)
        this.toggleMute(prevMuted)

        this.updateTimeTimer = window.setInterval(this.updateSeekRangeAndTimer, 333)
    }

    private handleQualitiesChange = () => {
        const qualities = this.getQualities()
        const qualitiesToDisplay = qualities.map((q) => q.height?.toString()).filter(notEmptyFilter)
        store.set(qualitiesAtom, qualitiesToDisplay)

        qualitiesToDisplay.push(AUTO_QUALITY_TITLE)

        if (this.isAdaptationModeEnabled()) {
            store.set(currentQualityAtom, AUTO_QUALITY_TITLE)
        } else {
            store.set(currentQualityAtom, this.getCurrentQuality()?.height?.toString() ?? AUTO_QUALITY_TITLE)
        }
    }

    //https://github.com/shaka-project/shaka-player/blob/9f603adefb36be001aef0c2fbea632aae4e61277/ui/presentation_time.js#L71
    private updateSeekRangeAndTimer = () => {
        const isOsdVisible = store.get(isOsdVisibleAtom)
        const seekRange = this.shaka.seekRange()
        if (isOsdVisible) {
            store.set(seekRangeAtom, seekRange)
        }
        if (this.shaka.isLive() && this.forceIsNotLive && Date.now() - this.forceIsNotLive > NOT_LIVE_THRESHOLD_MS) {
            const currentTime = this.videoElement.currentTime
            const timeDiff = seekRange.end - currentTime
            // console.log(`Current time: ${currentTime}`)
            // console.log(`    Seek end: ${seekRange.end}`)
            // console.log(`        Diff: ${timeDiff}`)
            store.set(isLiveAtom, timeDiff < NOT_LIVE_THRESHOLD_MS / 1000)
        }
    }

    cleanup = () => {
        this.listeners.forEach((listener) => {
            this.videoElement.removeEventListener(listener.event, listener.callback)
        })
    }

    setPlayerState = (state: PlayerState) => {
        this.playerState = state

        analytics.currentSourceState = state
    }

    handleBuffering(event: Event) {
        store.set(isBufferingAtom, (event as ShakaBufferingEvent).buffering)
        this.setPlayerState('buffering')
    }

    handlePlay() {
        store.set(isPlayingAtom, true)

        this.setPlayerState('playing')
        if (this.shaka.isLive() && this.shaka.seekRange().end - this.videoElement.currentTime > BUFFER_BEHIND) {
            this.forceIsNotLive = null
        }
    }

    handlePause() {
        store.set(isPlayingAtom, false)
        store.set(isLiveAtom, false)
        this.forceIsNotLive = Date.now()

        this.setPlayerState('paused')
    }

    async loadSource(url: string) {
        try {
            store.set(loadSourceErrorAtom, undefined)
            console.log('Going to play URL', url)
            await this.shaka.load(url)
            // this.seekToLive()
            console.log('Stream started successfully')
            if (AUTOPLAY) {
                await this.unpause()
            }

            const videoId = store.get(channelNameAtom) ?? url
            analytics.currentSourceData = {
                content_id: videoId,
                metric_type: LiveAnalyticMetricType.State,
                metric_value: 'playing',
                source_id: videoId,
            }

            analytics.reportVideoPlay(AnalyticsConversionStatus.SUCCESS, videoId)
        } catch (err) {
            // TODO: Auto-retry mechanism, bubble up event
            // send to statistic bad start, after n-count start trying different sources with sending history to getSourceUrl,
            // show UI, offer force-reload, send analytic event

            // show UI with button which will load from getSourceUrlForIIHF in useSource.ts

            // For example: error 7000, we can ignore, it's LOAD_INTERRUPTED, when we call it too often

            if ((err as PlayerError).code !== 7000) {
                void this.showRetryDialog()
            }
            console.error('Starting stream went wrong', url, err)

            analytics.reportError(err as PlayerError)
        }
    }

    showRetryDialog = async () => {
        try {
            await this.shaka.unload()
        } catch {}
        store.set(loadSourceErrorAtom, {
            title: 'Failed to load stream',
            buttonText: 'Retry',
        })

        this.errorsHistory = []
    }

    pause = async () => {
        this.videoElement.pause()
        store.set(isOsdVisibleAtom, true)
    }

    unpause = async () => {
        try {
            this.playPromise = this.videoElement.play()
            await this.playPromise

            if (this.isFirstPlay) {
                this.isFirstPlay = false
                ReactGA.event({
                    category: 'Video',
                    action: 'Play',
                    label: 'LIVE Video play',
                })
            }
        } catch (err) {
            this.handlePlayError(err as Error)
        }
    }

    togglePause = async () => {
        const isPlaying = store.get(isPlayingAtom)
        if (isPlaying) {
            this.pause()
        } else {
            await this.unpause()
        }
    }

    changeVolume = (volume: number, shouldUnmute = false) => {
        const volumeClamped = clamp(0, 1, volume)
        this.videoElement.volume = volumeClamped
        store.set(volumeAtom, volumeClamped)
        if (shouldUnmute && volumeClamped >= 0.1 && store.get(isMutedAtom)) {
            this.toggleMute(false)
        }
    }

    toggleMute = (force?: boolean) => {
        const newMuted = force ?? !this.videoElement.muted
        this.videoElement.muted = newMuted
        store.set(isMutedAtom, newMuted)
    }

    getIsVideoElementMuted = () => {
        return this.videoElement.muted
    }

    isAdaptationModeEnabled = () => {
        return this.shaka.getConfiguration().abr.enabled
    }

    toggleAdaptationMode = (enabled: boolean) => {
        this.shaka.configure({ abr: { enabled } })
    }

    setQuality = (quality: string) => {
        if (quality === AUTO_QUALITY_TITLE) {
            this.toggleAdaptationMode(true)
        } else {
            const activeAudioTrack = store.get(playerAudioAtom)
            const qualityToSet = this.getQualities().find((q) => {
                return q.language === activeAudioTrack && q.height?.toString() === quality
            })

            if (qualityToSet) {
                this.toggleAdaptationMode(false)
                this.shaka.selectVariantTrack(qualityToSet)
            }
        }

        this.handleQualitiesChange()
    }

    getQualities() {
        const lang = store.get(playerAudioAtom)
        const qualities = this.shaka.getVariantTracks().filter(isValidQuality).filter(isCorrectLanguage(lang))
        return qualities
    }

    getCurrentQuality(lang?: LangCode) {
        const activeQualities = this.getQualities().filter((track) => track.active)

        if (lang && activeQualities.length > 1) {
            return activeQualities.find(isCorrectLanguage(lang)) ?? null
        }

        return activeQualities[0] ?? null
    }

    /**
     * @throws when video does not support fullscreen on iOS
     * see https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1628805-webkitsupportsfullscreen
     */
    goFullScreen = () => {
        /**
         * iOS support
         */
        // @ts-expect-error
        if (this.videoElement.webkitEnterFullscreen) {
            // @ts-expect-error
            this.videoElement.webkitEnterFullscreen()
            // this.triggerEvent('togglefullscreen', true)

            return Promise.resolve()
        }

        return this.videoElement.requestFullscreen()
    }

    seekToLive = () => {
        if (this.shaka.isLive()) {
            this.videoElement.currentTime = this.shaka.seekRange().end
            this.unpause()
            store.set(isLiveAtom, true)
            this.forceIsNotLive = null
        }
    }

    /**
     * Listen to errors during actual playback
     */
    private handlePlaybackError = async (error: PlayerError) => {
        const errorCode = 'code' in error ? error.code : 'detail' in error ? (error as PlayerError).detail?.code : undefined
        analytics.reportError(error)
        console.error(error)
        if (errorCode && errorCode === util.Error.Code.BAD_HTTP_STATUS && error.detail && Array.isArray(error.detail.data) && error.detail.data[1] === 403) {
            const now = Date.now()
            this.errorsHistory.push({
                code: error.detail.code,
                dataCode: error.detail.data[1],
                timestamp: now,
            })

            let thresholdCount = 0
            let prevTimestamp = now
            for (let i = this.errorsHistory.length - 1; i >= 0 && thresholdCount < 8; i--) {
                const errorItem = this.errorsHistory[i]
                if (errorItem.code === errorCode) {
                    const diff = Math.abs(errorItem.timestamp - prevTimestamp)
                    // console.log(i, diff)
                    if (diff < 10000) {
                        thresholdCount++
                    } else {
                        this.errorsHistory.splice(i, 1)
                    }
                    prevTimestamp = errorItem.timestamp
                }
            }

            if (thresholdCount >= 8) {
                const errorMessage = `Player fatal failure, showing retry dialog. Errors history: ${JSON.stringify(this.errorsHistory)}`
                console.error(errorMessage)
                analytics.reportError(new Error(errorMessage))
                store.set(isPlayingAtom, false)
                this.showRetryDialog()
            }

            // console.log(this.errorsHistory.length)
            // console.log(this.errorsHistory)
        }
    }

    private async handlePlayError(error: Error) {
        if (isAutoplayNotAllowedError(error)) {
            await this.autoplayHandler.onPlayError()
            return
        }
        if (isAbortError(error)) {
            console.warn('Play aborted, ignoring error', error.message)
            return
        }

        analytics.reportError(error)
    }
}
