import { useMemo, useState } from 'react'

import { CameraIcon, QualitiesIcon } from '../../../Icons'
import { SettingsControlProps } from '../SettingsControl'

import { useQualities } from './useQualities'
import { useSource } from '../../../Player/hooks/useSource'

const cameras = [
    {
        name: 'Main - CS',
        url: 'https://europe-west3-tivio-production.cloudfunctions.net/getSourceUrlForIIHF',
        id: 'qQoAmUurFBNEGiSwG0xf'
    },
    {
        name: 'Main - EN',
        url: 'https://europe-west3-tivio-production.cloudfunctions.net/getSourceUrlForIIHF',
        id: 'a5jBWa46qfd1bE9OGJPp'
    },
    {
        name: 'Red corner',
        url: 'https://europe-west3-tivio-production.cloudfunctions.net/getSourceUrlForIIHF',
        id: 'PcjKYgUQd2sKtgdllxHN'
    },
    {
        name: 'Blue corner',
        url: 'https://europe-west3-tivio-production.cloudfunctions.net/getSourceUrlForIIHF',
        id: 'qvvhcKYcXxlQM3kNm1Kk'
    },
    {
        name: 'Referee',
        url: 'https://europe-west3-tivio-production.cloudfunctions.net/getSourceUrlForIIHF',
        id: 'WyjS3BQ4wlmwpclPrfz9'
    },
]

export const useMenuConfigs = () => {
    const {
        currentQuality,
        qualities,
        selectQuality,
    } = useQualities()

    const { changeActiveVideo } = useSource({})

    const [currentCamera, selectCamera] = useState(cameras[0].name)

    const qualitiesMenu: SettingsControlProps['subMenuConfigs'] | null = useMemo(() => {
        const showQualitiesMenu = qualities.length > 1

        return showQualitiesMenu ? {
            'quality': {
                menuTitle: 'Kvalita',
                itemCount: qualities.length,
                itemLabel: (index: number) => qualities[index],
                isActive: (index: number) => currentQuality ? index === qualities.indexOf(currentQuality) : false,
                onClick: (index: number) => {
                    if (currentQuality) {
                        const currentQualityIndex = qualities.indexOf(currentQuality)

                        if (currentQualityIndex !== -1 && index !== currentQualityIndex) {
                            selectQuality(qualities[index])
                        }
                    }
                },
                getActiveIndex: () => currentQuality ? qualities.indexOf(currentQuality) : undefined,
                iconComponent: QualitiesIcon,
                selectedValue: currentQuality ?? undefined,
            },
        } : null
    }, [currentQuality, qualities, selectQuality])

    const camerasMenu: SettingsControlProps['subMenuConfigs'] | null = useMemo(() => {

        return {
            'camera': {
                menuTitle: 'Cameras',
                itemCount: cameras.length,
                itemLabel: (index: number) => cameras[index].name,
                isActive: (index: number) => currentCamera ? index === cameras.findIndex(c => c.name === currentCamera) : false,
                onClick: async (index: number) => {
                    if (currentCamera) {
                        const currentCameraIndex = cameras.findIndex(c => c.name === currentCamera)

                        if (currentCameraIndex !== -1 && index !== currentCameraIndex) {
                            const currentCamera = cameras[index]
                            selectCamera(currentCamera.name)
                            await changeActiveVideo(currentCamera.id)
                        }
                    }
                },
                getActiveIndex: () => currentCamera ? cameras.findIndex(c => c.name === currentCamera) : undefined,
                iconComponent: CameraIcon,
                selectedValue: currentCamera ?? undefined,
            },
        }
    }, [changeActiveVideo, currentCamera])

    const subMenuConfigs: SettingsControlProps['subMenuConfigs'] = useMemo(() => ({
        ...qualitiesMenu,
        ...camerasMenu
    }), [camerasMenu, qualitiesMenu])

    return {
        subMenuConfigs,
    }
}
