import React from 'react'
import { css, keyframes, styled } from 'styled-components'
import { useAtom } from 'jotai'

import { PlayerUIState, Z_INDEX } from '../../constants'
import { PauseIcon, PlayIcon } from '../Icons'
import { Spinner } from './Spinner'
import { isBufferingAtom, isOsdVisibleAtom } from '../Player/state'


const pauseAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
`

const CenterWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 72px;
    height: 72px;
    cursor: pointer;
    z-index: ${Z_INDEX.BIG_PLAY_BUTTON};
    transition: all 250ms ease-in-out;
    transform: translate(-50%, -50%);
    &:hover {
        transform: translate(-50%, -50%) scale(1.1);
    }
`

const Circle = styled.div<{ $isPlaying: boolean, $isVisible: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.palette.grey[900]}E9;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    opacity: ${({ $isVisible }) => Number($isVisible)};
    transition: background-color 250ms ease-in-out, opacity 150ms ease-out;
    &:hover {
        background-color: ${({ theme }) => theme.palette.grey[900]};
    }
    ${({ $isPlaying }) => $isPlaying && css`
        animation: ${pauseAnimation} 500ms linear forwards;
    `}
`

interface Props {
    isPlaying: boolean
    playerState: PlayerUIState
    isLoading: boolean
    onClick: () => void
}

export const BigPlayButton = ({ isPlaying, isLoading, playerState, onClick }: Props) => {
    const [isBuffering] = useAtom(isBufferingAtom)
    const [isOsdVisible] = useAtom(isOsdVisibleAtom)
    const isSpinner = playerState === PlayerUIState.Video && (isBuffering || isLoading)
    const Icon = isPlaying ? PauseIcon : PlayIcon
    return (
        <CenterWrapper onClick={onClick} title={isPlaying ? 'Pauza' : 'Prehrať'}>
            <Circle $isPlaying={isPlaying} $isVisible={playerState === PlayerUIState.Intro || isOsdVisible}>
                <Icon size={24} />
            </Circle>
            {/* TODO: Don't show when REALLY playing, not just state is set to playing */}
            {isSpinner && <Circle $isPlaying={false} $isVisible={true}>
                <Spinner />
            </Circle>}
        </CenterWrapper>
    )
}
