import { createIcon } from './createIcon'


export const PauseIcon = createIcon({
    d: 'M7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21C8.10457 21 9 20.1046 9 19V5C9 3.89543 8.10457 3 7 3ZM17 3C15.8954 3 15 3.89543 15 5V19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z',
    displayName: 'PauseIcon',
    defaultProps: {
        size: 24,
        color: 'currentColor',
    },
    viewBox: '0 0 24 24',
})
