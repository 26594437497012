import React, { memo, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { ShakaEngine } from '../../engine/ShakaEngine'


const StyledVideo = styled.video`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

interface Props {
    url: string;
    poster?: string;
}

const VideoPlayerInner = ({ url, poster }: Props) => {
    const shakaEngineRef = useRef<ShakaEngine>()
    const initPlayer = useCallback(async (videoEl: HTMLVideoElement) => {
        // TODO: If we have shaka engine instance already, we should destroy it before creating a new one
        shakaEngineRef.current = new ShakaEngine(videoEl)
    }, [])

    // TODO: Call cleanup

    useEffect(() => {
        shakaEngineRef.current?.loadSource(url)
    }, [url])

    return (
        <StyledVideo
            // autoPlay={true}
            muted={false}
            controls={false}
            ref={initPlayer}
            poster={poster}
            playsInline={true}
        />
    )
}

export const VideoPlayer = memo(VideoPlayerInner)
