import { useAtom } from 'jotai'
import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Z_INDEX } from '../../constants'

import { getExistFullScreenMethod, getRequestFullScreenMethod, isFullScreenSupported, useIsFullScreen } from './hooks/fullscreen'
import { useIsFullScreenIos } from './hooks/useFullScreenIos'
import { isOsdVisibleAtom, playerControllerAtom } from './state'
// import { KeyboardShortcuts, Shortcuts } from '../keyboardShortcuts'

import type { ReactNode } from 'react'

// const logger = new Logger('FullscreenableVideoArea')

const Container = styled.div<{ fullscreen: boolean; scale?: number }>`
    position: relative;
    width: 100%;
    height: 100%;
    ${(props) =>
        props.fullscreen &&
        css`
            position: fixed;
            top: 0;
            left: 0;
        `}

    ${({ fullscreen, scale = 1 }) => {
        if (fullscreen || scale < 0 || scale === 1) {
            return ''
        }

        const scaledSizePercentage = 100 * scale
        const topLeftShiftPercentage = -(scaledSizePercentage - 100) / 2

        return css`
            width: ${scaledSizePercentage}%;
            height: ${scaledSizePercentage}%;
            top: ${topLeftShiftPercentage}%;
            left: ${topLeftShiftPercentage}%;
        `
    }}
`

const PlayerClickArea = styled.div<{ $isOsdVisible: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: ${(props) => (props.$isOsdVisible ? 'auto' : 'none')};
    z-index: ${Z_INDEX.CLICK_AREA};
`

type Props = {
    /**
     * Player video can be scaled. Used in WebVideoDetailBanner to "hide" black stripes on video.
     * Should be positive number.
     * TODO now we leave ot here, but consider to move it to some special component (something like "ScalableVideoContainer")
     */
    scale?: number;
    // customShortcuts?: Partial<Shortcuts>
    enableKeyboardShortcuts?: boolean;
    onClick?: () => void;
    onMouseMove?: () => void;
    children: (isFullscreen: boolean, toggle: () => void) => ReactNode;
};

export const FullscreenableVideoArea = ({
    children,
    scale,
    // customShortcuts,
    // enableKeyboardShortcuts,
    // onClick,
    onMouseMove,
}: Props) => {
    // const { video } = useContext(PlayerContext)
    const ref = useRef<HTMLDivElement | null>(null)
    const [playerController] = useAtom(playerControllerAtom)
    const [isOsdVisible] = useAtom(isOsdVisibleAtom)
    const isFull = useIsFullScreen(ref)
    /**
     * TODO there is an iOS bug that when source is changed during fullscreen
     * the next video gets aligned to top of the screen instead of being
     * centered vertically
     * The same bug is present e.g. here https://novinky.cz/ so probably it is an iOS bug
     */
    const isFullIos = useIsFullScreenIos()
    const isFullscreen = isFull || isFullIos

    const toggle = useCallback(() => {
        const element = ref.current
        if (!element) {
            return
        }

        const isSupported = isFullScreenSupported(element)

        const enter = async () => {
            if (isSupported) {
                const requestFullScreen = getRequestFullScreenMethod(element)
                await requestFullScreen?.()
            } else {
                try {
                    // iOS support
                    await playerController.goFullScreen()
                } catch (error) {
                    console.error('Fullscreen not supported', error)
                }
            }
        }

        const exit = async () => {
            const exitFullScreen = getExistFullScreenMethod()
            await exitFullScreen?.()
        }

        isFullscreen ? exit() : enter()
    }, [isFullscreen, playerController])

    /**
     * Player click area
     */
    // const [ad] = useAd()

    // const innerOnClick = useCallback(() => {
    //     if (ad) {
    //         ad.click()
    //         return
    //     }

    //     if (isOsdVisible) {
    //         onClick?.()
    //         return
    //     }

    //     onMouseMove()
    // }, [ad, isOsdVisible, onClick, onMouseMove])

    return (
        <Container
            ref={ref}
            // fullscreen={isMobileBrowser() && isFullscreen}
            fullscreen={isFullscreen}
            scale={scale}
        >
            {/* <KeyboardShortcuts
                toggleFullscreen={toggle}
                customShortcuts={customShortcuts}
                enabled={enableKeyboardShortcuts}
            > */}
            <PlayerClickArea
                $isOsdVisible={isOsdVisible}
                onDoubleClick={toggle}
                // onClick={innerOnClick}
                onMouseMove={onMouseMove}
            />
            {children(isFullscreen, toggle)}
            {/* </KeyboardShortcuts> */}
        </Container>
    )
}
