import { createStyledBreakpointsTheme } from 'styled-breakpoints'

// TODO: Provide values for all fields used across project, or a null exception may happen
export const defaultTheme = {
    ...createStyledBreakpointsTheme(),
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#0C0D10',
        },
        secondary: {
            main: '#0C0D10',
            contrastText: '#fff',
        },
        grey: {
            900: '#0C0D10',
            800: '#999',
            500: '#ddd',
        },
        red: {
            500: '#ff0000',
        },
        text: {
            primary: '#fff',
        },
    },
    zIndex: {
        tooltip: 10,
    },
}
