import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
    ContextMenuContainer,
    ContextMenuWrapper,
    LeftMenuLabelContainer,
    MenuLabel,
    MenuLabelContainer,
    MenuTitle,
    MenuTitleContainer,
    RightMenuLabelContainer,
} from '../../FloatingContextMenu'
import { IconButton } from '../../IconButton'
import { CheckIcon, ChevronLeft, SettingsIcon } from '../../Icons'
import { IconComponentProps } from '../../Icons/createIcon'


const MenuSeparator = styled.hr`
    margin: 12px 0;
    height: 1px;
    opacity: 0.15;
    background-color: #fff;
    border: none;

    ${props => props.theme.breakpoints.down('sm')} {
        margin: 8px 0;
    }
`

type SettingsControlBase = {
    menuTitle: string
    itemCount: number
    itemLabel: (index: number) => string
    isActive: (index: number) => boolean
    onClick: (index: number) => void
    getActiveIndex: () => number | undefined
    selectedValue?: string
}

export type SettingsControlType = SettingsControlBase & SettingsControlBase & {
    iconComponent?: React.ComponentType<IconComponentProps>
}

export type SettingsControlProps = {
    subMenuConfigs: Record<string, SettingsControlType>
}

const SettingsMenu = styled(ContextMenuWrapper)`
    position: absolute;
    right: 0;
    bottom: 3rem;
`

export const SettingsControl: React.FC<SettingsControlProps> = ({
    subMenuConfigs,
}) => {
    const [settingsMenuOpened, setSettingsMenuOpened] = useState(false)
    const toggleShowSettings = () => setSettingsMenuOpened(prev => !prev)
    const [activeSubMenuId, setActiveSubMenuId] = useState<string | undefined>()
    const settingsMenuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutsideSettingsMenu = (event: MouseEvent) => {
            if (!settingsMenuRef.current?.contains(event.target as Node)) {
                setSettingsMenuOpened(false)
            }
        }

        // We need capture, so .contains check is executed before activeSubMenuId is set
        const listenerOptions: AddEventListenerOptions = {
            capture: true,
        }

        if (settingsMenuOpened) {
            document.addEventListener('click', handleClickOutsideSettingsMenu, listenerOptions)
        }

        return () => {
            document.removeEventListener('click', handleClickOutsideSettingsMenu, listenerOptions)
        }
    }, [settingsMenuOpened])

    const renderSettingsMenuContents = useCallback(() => {
        return activeSubMenuId
            ? (
                <>
                    <MenuTitleContainer
                        onClick={() => setActiveSubMenuId(undefined)}
                    >
                        <ChevronLeft size={16} />
                        <MenuTitle>
                            {subMenuConfigs[activeSubMenuId].menuTitle}
                        </MenuTitle>
                    </MenuTitleContainer>
                    <MenuSeparator />
                    {
                        // Render specific submenu entries
                        Array.from({ length: subMenuConfigs[activeSubMenuId].itemCount }, (_, i) => i).map(i => {
                            const config = subMenuConfigs[activeSubMenuId]
                            const onClick = () => {
                                if (config.isActive(i)) {
                                    return
                                }
                                config.onClick(i)
                                setActiveSubMenuId(undefined)
                                setSettingsMenuOpened(false)
                            }

                            const isActive = config.isActive(i)

                            return (
                                <MenuLabelContainer
                                    key={i}
                                    onClick={onClick}
                                    $active={isActive}
                                    $condensed
                                >
                                    <LeftMenuLabelContainer>
                                        <MenuLabel $bold={isActive}>{config.itemLabel(i)}</MenuLabel>
                                    </LeftMenuLabelContainer>
                                    {
                                        isActive
                                            ? <RightMenuLabelContainer>
                                                <CheckIcon
                                                    size={20}
                                                    color="currentColor"
                                                />
                                            </RightMenuLabelContainer>
                                            : undefined}
                                </MenuLabelContainer>
                            )
                        })
                    }
                </>
            )
            : (
                // Render all submenus
                Object.entries(subMenuConfigs).map(([subMenuId, config]) => {
                    const icon = getIconComponent(config)

                    return (
                        <MenuLabelContainer
                            key={subMenuId}
                            onClick={() => setActiveSubMenuId(subMenuId)}
                        >
                            <LeftMenuLabelContainer>
                                {icon}
                                <MenuLabel $bold>{config.menuTitle}</MenuLabel>
                            </LeftMenuLabelContainer>
                            <RightMenuLabelContainer>
                                {config.selectedValue}
                            </RightMenuLabelContainer>
                        </MenuLabelContainer>
                    )
                })
            )
    }, [activeSubMenuId, subMenuConfigs])

    return (
        <ContextMenuContainer
            ref={settingsMenuRef}
        >
            <IconButton
                title="Nastavenie prehrávača"
                variant="transparent"
                icon={SettingsIcon}
                onClick={() => {
                    toggleShowSettings()
                    setActiveSubMenuId(undefined)
                }}
            />
            {settingsMenuOpened && (
                <SettingsMenu>
                    {renderSettingsMenuContents()}
                </SettingsMenu>
            )}
        </ContextMenuContainer>
    )
}

function getIconComponent(settings: SettingsControlType): React.JSX.Element | undefined {
    if ('iconComponent' in settings && settings.iconComponent) {
        return <settings.iconComponent
            size={20}
            color="#fff"
        />
    }

    return undefined
}
