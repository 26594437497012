import { useEffect } from "react";
import ReactGA from "react-ga4";


const TRACKING_ID = "G-3Y69Y2X228";

/**
 * Initialize Google Analytics.
 */
export const GoogleAnalytics = () => {
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return null;
};
