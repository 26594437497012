import React, { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

import { useIsBlocked } from './hooks'


const Typography = styled.div`
    font-size: 1.5rem;
    color: black;
`

export const WhitelistBlocker = ({ children }: PropsWithChildren) => {
    const isBlocked = useIsBlocked()
    return <>{isBlocked ? <Typography>Prístup bol zamietnutý</Typography> : children}</>
}
