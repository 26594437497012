import React, { useCallback } from 'react'

import { BarPicker } from './BarPicker'
import { useAtom } from 'jotai'
import { playerControllerAtom, volumeAtom } from '../Player/state'


interface Props {
    className?: string
    widthPx: number
    radiusPx: number
    onDraggingChange?: (dragging: boolean) => void
}

export const VolumePicker: React.FC<Props> = (props) => {
    const [volume] = useAtom(volumeAtom)
    const [playerController] = useAtom(playerControllerAtom)

    const onChange = useCallback((value: number) => {
        playerController.changeVolume(value, true)
    }, [playerController])

    return (
        <BarPicker
            value={volume ?? 1}
            onChange={onChange}
            className={props.className}
            widthPx={props.widthPx}
            radiusPx={props.radiusPx}
            onDraggingChange={props.onDraggingChange}
        />
    )
}
