import { PlayerController } from '../types/controller'

import { errorIsError, isAbortError, isAutoplayNotAllowedError } from './utils'


export class AutoplayHandler {
    private previousMutedState = false
    private triedToPlayMuted = false

    constructor(private playerController: PlayerController) {}

    async onPlayError(): Promise<void> {
        console.log('Autoplay not allowed, trying to play muted')

        this.previousMutedState = this.playerController.getIsVideoElementMuted()
        this.playerController.toggleMute(true)
        if (!this.previousMutedState && !this.triedToPlayMuted) {
            await this.play()
        }
    }

    private async play(): Promise<void> {
        try {
            this.triedToPlayMuted = true
            await this.playerController?.unpause()
        } catch (error) {
            this.playerController.toggleMute(this.previousMutedState)

            if (errorIsError(error)) {
                if (isAutoplayNotAllowedError(error)) {
                    this.logNotAllowed()
                    return
                }
                if (isAbortError(error)) {
                    console.log('Play aborted, ignoring error', error.message)
                    return
                }
            }
        }
    }

    private logNotAllowed() {
        console.log('Autoplay not allowed, waiting for user interaction to start playback')
    }
}
