import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { ExclamationIcon } from './Icons/'
import { overlayStyles, Z_INDEX } from "../constants";

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
`

const Message = styled.p`
    font-size: 1rem;
    margin: 0;
`

const StyledError = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`

const ErrorBackdrop = styled.div`
    ${overlayStyles};
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${Z_INDEX.ERROR};
`

export interface ErrorOverlayProps {
    title: string
    message?: string
    buttonText: string
    onButtonClick: () => void
}

export const ErrorOverlay = ({ title, message, buttonText, onButtonClick }: ErrorOverlayProps) => {
    return (
        <ErrorBackdrop>
            <StyledError>
                <ExclamationIcon size={48} />
                <Title>{title}</Title>
                {message && <Message>{message}</Message>}
                <Button size="large" onClick={onButtonClick}>{buttonText}</Button>
            </StyledError>
        </ErrorBackdrop>
    )
}
