import { useAtom } from 'jotai'
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react'
import { styled } from 'styled-components'

import { Z_INDEX } from '../../constants'
import { isOsdVisibleAtom, isPlayingAtom, playerControllerAtom } from '../Player/state'

import { Gradient } from './Gradient'
import { LiveButton } from './LiveButton'
import { Toolbar } from './Toolbar'


const HIDE_TIMEOUT_MS = 3000

const Container = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: ${Z_INDEX.OSD};
`

interface Props {
    isFullscreen: boolean
    toggleFullscreen: () => void
}

export const Osd = ({ isFullscreen, toggleFullscreen }: Props) => {
    const [isVisible, setIsVisible] = useAtom(isOsdVisibleAtom)
    const timeoutRef = useRef<number>()
    const [playerController] = useAtom(playerControllerAtom)
    const [isPlaying] = useAtom(isPlayingAtom)

    const handleMouseMove = useCallback(() => {
        const timeout = timeoutRef.current

        if (timeout) {
            clearTimeout(timeout)
        }

        setIsVisible(true)
        timeoutRef.current = window.setTimeout(() => {
            setIsVisible(false)
        }, HIDE_TIMEOUT_MS)
    }, [setIsVisible])
    
    useEffect(() => {
        if (isPlaying) {
            handleMouseMove()
        }
    }, [isPlaying, handleMouseMove])

    const handleDoubleClick = useCallback(() => {
        toggleFullscreen()
    }, [toggleFullscreen])

    const preventPropagation = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
    }, [])

    return (
        <Container
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseMove}
            onClick={playerController.togglePause}
            onDoubleClick={handleDoubleClick}
        >
            <Gradient
                $height="260px"
                $isVisible={isVisible}
            >
                <div onClick={preventPropagation}>
                    <div
                        style={{
                            width: 'fit-content',
                        }}
                    >
                        <LiveButton />
                    </div>
                    <Toolbar
                        isFullscreen={isFullscreen}
                        toggleFullscreen={toggleFullscreen}
                    />
                </div>
            </Gradient>
        </Container>
    )
}
