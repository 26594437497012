export interface AnalyticsEventTechnology {
    /**
     * Bundle version that user is currently using
     */
    bundle_version?: string;

    /**
     * Device type (e.g. mobile, tv, web, etc.) TODO define union type / enum for this
     */
    device_type?: string;

    /**
     * Operating system that device is using (e.g. windows, tizen, ios, etc.) TODO define union type / enum for this
     */
    os?: string;

    /**
     * Version of operating system that device is using
     */
    os_version?: string;
}

export interface AnalyticsEventBase<T extends AnalyticsEventName | unknown = unknown> {
    /**
     * Name of the event. Based on this {@link custom_data} attribute changes its structure.
     */
    event_name: T extends AnalyticsEventName ? T : AnalyticsEventName;

    /**
     * Bundle version that user is currently using
     */
    technology: AnalyticsEventTechnology;

    /**
     * Tells which user logs event with some additional user's info
     */
    user: AnalyticsEventUser;

    /**
     * Tells from where the event is originated from.
     */
    origin: AnalyticsEventOrigin;
}

export interface AnalyticsEventUser {
    /**
     * Firebase id of currently logged-in user. For user who is not logged in we will generate anonym ID
     */
    user_id: string;
    /**
     * List of monetization IDs, which user has active in the time
     */
    user_monetizations: string[];

    /**
     * User's location
     */
    user_location: {
        continent: string;
        country: string;
        city: string;
    };

    /**
     * User's profile, optional
     */
    user_profile?: {
        [key: string]: string | number | boolean | undefined;
    };
}

export interface AnalyticsEventOrigin {
    /**
     * Firebase id of current organization.
     */
    organization_id?: string;

    /**
     * Full url of current page, including protocol, query parameters and hash (e.g. 'https://play.joj.sk/player/pa6GEiwNrZvdz0SYwRmG?type=VIDEO#somewhere').
     */
    page_location?: string;

    /**
     * Html title of the page - what we have in `<head><title>TITLE</title></head>` tag.
     */
    page_title?: string;
}

/**
 * Event data that we send to our analytics function (logEvent).
 * All properties are in snake case to match google pub sub naming convention.
 */
export interface AnalyticsEvent<T extends AnalyticsEventName | unknown = unknown> extends AnalyticsEventBase<T> {
    /**
     * Arbitrary data, that change based on event type.
     */
    custom_data?: AnalyticsEventCustomData<T>;
}

export type VideoViewCustomData = {
    video_id: string

    /**
     * Id of organization that video belongs to.
     */
    organization_id?: string

    video_name: string

    /**
     * Season number, that this episode belongs to. Is null if video is not a part of any series.
     */
    season_number?: string

    /**
     * Episode number of this episode. Is null if video is not a part of any series.
     */
    episode_number?: string

    /**
     * Series name, that this video belongs to. Is null if video is not a part of any series.
     */
    series_name?: string

    /**
     * Series id, that this video belongs to. Is null if video is not a part of any series.
     */
    series_id?: string

    /**
     * Returns if the video is part of series, simple video, playlist, cut, trailer or tv program
     */
    video_type?: string

    /**
     * Monetization id which user used to be able to watch this video
     */
    video_monetization_id?: string

    /**
     * Current user's active subscription (monetization) id.
     */
    user_subscription_id?: string
}

export type VideoPlayCustomData = {
    video_id: string

    /**
     *  Tells in what percent of a video user is in (e.g. '5', '10', etc.).
     */
    checkpoint: string

    /**
     * Video duration in milliseconds.
     */
    video_duration?: string

    /**
     * Id of organization that video belongs to.
     */

    organization_id?: string

    /**
     * Series id, that this video belongs to. Is null if video is not a part of any series.
     */
    series_id?: string

    /**
     * Video type represents whether the video is a trailer, cut , regular video, etc. See {@link  VideoType}
     */
    video_type?: string

    /**
     * Monetization id which user used to be able to watch this video
     */
    video_monetization_id?: string
}

export type AliveCustomData = {
    /***
     * Stringify version of array of live events
     */
    events: string
}

export type ConversionCustomData = {
    /***
     * Name of the conversion specified in AnalyticsConversionName
     */
    name: string

    /***
     * status of conversion, in general failed / success
     */
    status: string

    /***
     * Target type specified in AnalyticsConversionTarget
     */
    target_type: string

    /***
     * Id of target, e.g. videoId, monetizationId
     * This parameter is mandatory for some conversions (e.g. saving videoId when visiting video page)
     * and optional for some others (e.g. not saving anything when visiting profile page).
     */
    target_id?: string

    /***
     * Path from which user came to the screen. It is composed path consist of screen, elements and so on.
     */
    path: string

    /***
     * Param of path from which user came to the screen.
     */
    path_param?: string
}

export type ErrorCustomData = {
    /**
     * Whole error object with all attributes.
     */
    // TODO can't send object here unfortunately, JSON-stringified object will be sent
    error: string
    /**
     * error code
     */
    error_code: string

    /**
     * error message
     */
    error_message: string

    // TODO can't send array of objects here unfortunately, JSON-stringified array will be sent
    // player_capabilities: string

    prefer_http?: string

    // TODO can't send object here unfortunately, JSON-stringified object will be sent
    browser_info?: string
}

export type ErrorPlayerCustomData = AliveEvent & ErrorCustomData

/**
 * Custom event data depending on the type of the event that we send.
 */
export type AnalyticsEventCustomData<T extends AnalyticsEventName | unknown = unknown> =
    T extends AnalyticsEventName.VideoView ? VideoViewCustomData :
    T extends AnalyticsEventName.VideoPlay ? VideoPlayCustomData :
    T extends AnalyticsEventName.Alive ? AliveCustomData :
    T extends AnalyticsEventName.ErrorPlayer ? ErrorPlayerCustomData :
    T extends AnalyticsEventName.Error ? ErrorCustomData :
    { [key: string]: string }

/**
 * List of events that we send to analytics.
 */
export enum AnalyticsEventName {
    Alive = "alive",
    AdView = "adView",
    Conversion = "conversion",
    Error = "error",
    ErrorPlayer = "errorPlayer",
    Purchase = "purchase",
    VideoPlay = "videoPlay",
    VideoView = "videoView",
}

export enum LiveAnalyticMetricType {
    Audio = "audio",
    Error = "error",
    Quality = "quality",
    State = "state",
}

export type AliveEvent = {
    /**
     * if user consume some content, send its ID
     */
    content_id?: string;

    /**
     * type of the metric - audio / quality / state
     */
    metric_type: LiveAnalyticMetricType;

    /**
     * value of the metric
     */
    metric_value: any;

    /**
     * id of source which is being played - URL can be used
     */
    source_id?: string;

    /**
     * Value between 0 and 5 * 60 * 1000 (5 minutes) - describe when the event was fired within analytics window
     */
    timestamp: number;
};

/**
 * For conversion purchases
 */
export enum AnalyticsConversionStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

/**
 * List of conversion types that we send to analytics.
 */
export enum AnalyticsConversionName {
    CLICK = "click",
    PLAY = "play",
    PURCHASE = "purchase",
    VISIT = "visit",
}
