import { styled } from 'styled-components'

import { Z_INDEX } from '../../constants'
import { Gradient } from '../Osd/Gradient'
import { TeamsProps } from '../Teams'

const Wrapper = styled.div<{ $bgImage: string }>`
    position: absolute;
    /* background-image: url(${({ $bgImage }) => $bgImage}); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: ${Z_INDEX.INTRO};
`

export interface PlayerIntroProps extends TeamsProps {
    backgroundImage: string
    onClick: () => void
}

export const PlayerIntro = ({ backgroundImage, onClick, ...teamsProps }: PlayerIntroProps) => {
    return (
        <Wrapper $bgImage={backgroundImage} onClick={onClick}>
            <Gradient $height="270px" $isVisible={true} />
        </Wrapper>
    )
}
