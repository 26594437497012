import React from 'react'
import styled from 'styled-components'

import { Button, ButtonProps } from './Button'


const StyledIconButton = styled(Button)`
    aspect-ratio: 1;
    padding: 0 4px;
    gap: 0;
`

export interface IconButtonProps extends Omit<ButtonProps, 'children' | 'leftIcon'> {
    icon: ButtonProps['leftIcon'];
}

export const IconButton: React.FC<IconButtonProps> = ({ icon, ...props }) => {
    return <StyledIconButton
        {...props}
        leftIcon={icon}
    />
}
