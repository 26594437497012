import React from 'react'
import styled from 'styled-components'

import { IconComponentProps } from './Icons/createIcon'
import { Pill, PillProps, PillSize } from './Pill'


const getIconSize = ({ size }: { size?: PillSize }) => {
    switch (size) {
        case 'small':
            return 18
        case 'large':
            return 28
        default:
            return 22
    }
}

const IconWrapper = styled.div`
    flex-shrink: 0;
    & svg {
        display: block;
    }
`

const StyledButton = styled(Pill)`
    padding: 0 16px;
    align-items: center;
    user-select: none;
`

const Label = styled.span`
    padding: ${({ theme }) => theme.spacing(0.75)}px 0;
    font-weight: 600;
`

export interface ButtonProps extends Omit<PillProps, 'onClick'> {
    label?: string
    leftIcon?: React.ForwardRefExoticComponent<Omit<IconComponentProps, 'ref'> & React.RefAttributes<SVGSVGElement>>
    className?: string
    isDisabled?: boolean
    // onClick?: (event: React.MouseEvent) => void
    onClick?: () => void
    isSubmit?: boolean
}

export const Button = ({ children, label, leftIcon: LeftIcon, variant, size, isDisabled, className, onClick, isSubmit, ...props }: ButtonProps) => {
    const iconSize = getIconSize({ size })
    const submitButtonProps = isSubmit
        ? {
            role: 'button',
            type: 'submit',
            component: 'button' as React.ElementType,
        }
        : {}
    return (
        <StyledButton
            className={className}
            onClick={isDisabled ? undefined : onClick}
            variant={isDisabled ? 'disabled' : variant}
            aria-disabled={isDisabled}
            size={size}
            {...submitButtonProps}
            {...props}
        >
            {LeftIcon ? (
                <IconWrapper>
                    <LeftIcon
                        size={iconSize}
                        color="currentColor"
                    />
                </IconWrapper>
            ) : null}
            {children}
            {label && <Label>{label}</Label>}
        </StyledButton>
    )
}
