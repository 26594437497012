import { useCallback, useRef } from 'react'

import { useOnGlobalEvent } from '../../../helpers/utils'


export const useDrag = (onDrag: (e: MouseEvent) => void, onDraggingChange?: (is: boolean) => void) => {
    // classic `onDrag` is not used because it works inconsistently in chrome.
    // It does not fire correctly on the first one or two tries
    // then it starts working correctly

    // Also there is a bug of the `onDrag` event in chrome
    // which causes the last 'drag' event to always fire at strange coordinates, e.g. x: -275
    // https://stackoverflow.com/questions/12128216/html5-drag-release-offsetx-offsety-jump/47241403

    const isDraggingRef = useRef<boolean>(false)

    const onMouseDown = useCallback(() => {
        isDraggingRef.current = true
        onDraggingChange?.(true)
    }, [onDraggingChange])

    const onMouseMove = useCallback((e: Event) => {
        if (isDraggingRef.current) {
            onDrag(e as MouseEvent)
        }
    }, [onDrag])

    const onMouseUp = useCallback(() => {
        isDraggingRef.current = false
        onDraggingChange?.(false)
    }, [onDraggingChange])

    useOnGlobalEvent('mousemove', onMouseMove)
    useOnGlobalEvent('mouseup', onMouseUp)

    return { onMouseDown }
}
