import { useAtom } from 'jotai'

import { currentQualityAtom, playerControllerAtom, qualitiesAtom } from '../../../Player/state'


export const useQualities = () => {
    const [qualities] = useAtom(qualitiesAtom)
    const [currentQuality] = useAtom(currentQualityAtom)
    const [playerController] = useAtom(playerControllerAtom)

    return {
        qualities,
        currentQuality,
        selectQuality: playerController.setQuality,
    }
}
