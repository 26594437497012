import { Provider } from 'jotai'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { StateWrapper } from './components/state/StateWrapper'
import { WhitelistBlocker } from './components/WhitelistBlocker'
import { store } from './store'
import { defaultTheme } from './theme/config'
import { GoogleAnalytics } from './analytics/GoogleAnalytics'


function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <GoogleAnalytics />
                <WhitelistBlocker>
                    <StateWrapper />
                </WhitelistBlocker>
            </ThemeProvider>
        </Provider>
    )
}

export default App
