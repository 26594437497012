import React, { SVGProps } from 'react'


interface CreateIconOptions {
    /**
     * The icon `svg` viewBox
     * @default "0 0 24 24"
     */
    viewBox?: string
    /**
     * The `svg` path or group element
     * @type React.ReactElement | React.ReactElement[]
     */
    path?: React.ReactElement | React.ReactElement[]
    /**
     * If the `svg` has a single path, simply copy the path's `d` attribute
     */
    d?: string
    /**
     * The `svg` path props if `d` is provided
     */
    dPathProps?: SVGProps<SVGPathElement>
    /**
     * The stroke width of the icon, if `d` is provided
     */
    strokeWidth?: number
    /**
     * The display name useful in the dev tools
     */
    displayName?: string
    /**
     * Default props automatically passed to the svg component
     */
    defaultProps?: IconComponentProps
    /**
     * If true, stroke will be used on paths instead of fill
     */
    useStrokeInsteadOfFill?: boolean
}

export interface IconComponentProps extends Omit<SVGProps<SVGSVGElement>, 'color'> {
    /**
     * Color of the icon. Accepts a string. Use this over fill or stroke property.
     * @returns The color of the icon
     */
    color?: string
    /**
     * The size of the icon in pixels
     */
    size?: number
}

/**
 * Factory function to create an icon component
 * @param param0 Configuration object
 * @returns An icon component with {@link IconComponentProps}
 */
export const createIcon = ({
    viewBox = '0 0 24 24',
    d: pathDefinition,
    displayName,
    defaultProps,
    dPathProps,
    path: pathProp,
    useStrokeInsteadOfFill = false,
    strokeWidth,
}: CreateIconOptions) => {
    const Component = React.forwardRef<SVGSVGElement, IconComponentProps>((componentProps, ref) => {
        const paths = React.Children.toArray(pathProp) as React.ReactElement<SVGProps<SVGPathElement>>[]
        const size = componentProps.size || defaultProps?.size

        const color = componentProps.color || defaultProps?.color
        const fillOrStroke = useStrokeInsteadOfFill ? { stroke: color } : { fill: color }

        return (
            <svg
                ref={ref}
                viewBox={viewBox}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...defaultProps}
                {...componentProps}
                {...(size && { width: size, height: size })}
                color={color}
            >
                {paths.length > 0 ? (
                    // TODO: not all elements are paths, can be g, circle, defs, etc.
                    paths.map((path) => {
                        return React.cloneElement(path, {
                            key: path.props.d,
                            ...fillOrStroke,
                        })
                    })
                ) : (
                    <path
                        d={pathDefinition}
                        strokeWidth={strokeWidth}
                        fill="none"
                        {...dPathProps}
                        {...fillOrStroke}
                    />
                )}
            </svg>
        )
    })

    Component.displayName = displayName

    return Component
}
