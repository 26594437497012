export const Z_INDEX = {
    BIG_PLAY_BUTTON: 10,
    INTRO: 9,
    OSD: 8,
    CLICK_AREA: 7,
    ERROR: 11,
}

export enum PlayerUIState {
    Intro,
    Video,
}

export enum UIState {
    Countdown,
    Player,
    AfterEvent,
}

export const overlayStyles = `
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;`
