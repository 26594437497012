import React, { HTMLAttributes } from 'react'
import styled, { type DefaultTheme } from 'styled-components'


export type PillVariant = 'primary' | 'secondary' | 'transparent' | 'disabled'
export type PillSize = 'small' | 'default' | 'large'

const getBackgroundColor = ({ theme, $variant }: { theme: DefaultTheme; $variant?: PillVariant }) => {
    switch ($variant) {
        case 'primary':
            return theme.palette.primary.main
        case 'secondary':
            return theme.palette.secondary.main
        case 'disabled':
            return theme.palette.grey[800]
        case 'transparent':
            return 'transparent'
        default:
            return theme.palette.primary.main
    }
}

const getTextColor = ({ theme, $variant }: { theme: DefaultTheme; $variant?: PillVariant }) => {
    switch ($variant) {
        case 'primary':
            return theme.palette.primary.contrastText
        case 'secondary':
            return theme.palette.secondary.contrastText
        case 'disabled':
            return theme.palette.grey[500]
        case 'transparent':
            return theme.palette.text.primary
        default:
            return theme.palette.primary.contrastText
    }
}

const getHeight = ({ $size }: StyledPillProps) => {
    switch ($size) {
        case 'small':
            return '30px'
        case 'large':
            return '40px'
        default:
            return '32px'
    }
}

const getFontSize = ({ $size }: StyledPillProps) => {
    switch ($size) {
        case 'small':
            return '10px'
        case 'large':
            return '14px'
        default:
            return '12px'
    }
}

type StyledPillProps = addPrefixToObject<Pick<PillProps, 'isFullWidth' | 'variant' | 'gap' | 'size'>, '$'>

const StyledPill = styled.div<StyledPillProps>`
    display: ${({ $isFullWidth }) => ($isFullWidth ? 'flex' : 'inline-flex')};
    justify-content: center;
    align-items: center;
    gap: ${({ $gap }) => $gap}px;
    font-size: ${getFontSize};
    color: ${getTextColor};
    letter-spacing: 0.28px;
    background-color: ${getBackgroundColor};
    & > * {
        color: inherit;
        font-size: inherit;
    }
    min-height: ${getHeight};
    border-radius: 20px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    ${(props) =>
        props.$variant === 'disabled'
            ? 'cursor: not-allowed;'
            : ''}
`

export interface PillProps extends HTMLAttributes<HTMLDivElement> {
    isFullWidth?: boolean
    variant?: PillVariant
    component?: React.ElementType
    /**
     * Gap between icons and text in px
     * @default 6
     */
    gap?: number
    /**
     * Size of the pill (small, default, large)
     * @default default
     */
    size?: PillSize
    className?: string
    children?: React.ReactNode
}

export const Pill: React.FC<PillProps> = ({
    variant,
    component = 'div',
    gap = 6,
    size = 'default',
    isFullWidth = true,
    className,
    children,
    ...restProps
}) => {
    return (
        <StyledPill
            as={component}
            $gap={gap}
            $variant={variant}
            $isFullWidth={isFullWidth}
            className={className}
            $size={size}
            {...restProps}
        >
            {children}
        </StyledPill>
    )
}
