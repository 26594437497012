/**
 * Check if the domain which the embed player is hosted on is whitelisted
 */
export const isHostedOnWhitelistedDomain = () => {
    // TODO: Obfuscate or else it's easy to find in the compiled code
    const whitelist = [
        'ms2024.joj.sk',
        'live.joj.sk',
        'plus.joj.sk',
        'joj.sk',
        'iihf.embed.tivio.studio',
        'iihf-player.web.app',
        'iihf-player.pages.dev',
    ]

    const host = window.location.hostname
    return whitelist.includes(host.toLowerCase())
}

/**
 * Get the parent URL of the iframe
 */
const getParentUrl = () => {
    try {
        return window.location.ancestorOrigins[0]
    } catch {
        return document.referrer
    }
}

/**
 * Check if the iframe is embedded on a whitelisted domain
 */
export const isIframeEmbeddedOnWhitelistedDomain = () => {
    const whitelist = [
        'ms2024.joj.sk',
        'live.joj.sk',
        'plus.joj.sk',
        'joj.sk',
        'tiviostudio.github.io',
        'iihf.embed.tivio.studio',
        'iihf-player.web.app',
        'iihf-player.pages.dev',
    ]

    try {
        const parentHost = new URL(getParentUrl()).hostname
        return whitelist.includes(parentHost.toLowerCase())
    } catch {
        return false
    }
}

/**
 * Check if the player is embedded in an iframe or the URL is accessed directly
 * @returns true if the player is embedded in an iframe
 */
export const isIframed = () => {
    return window !== window.top
}
