import { useState } from 'react'

// TODO
export const useIsFullScreenIos = () => {
    const [isFullscreen] = useState(false)
    // const { video } = useContext(PlayerContext)

    // // iOS support
    // useEffect(() => {
    //     if (!video.engine) {
    //         return
    //     }

    //     const listener = (isFull: boolean) => {
    //         setIsFullscreen(isFull)
    //     }

    //     video.engine.addEventListener('togglefullscreen', listener)

    //     return () => {
    //         video.engine?.removeEventListener('togglefullscreen', listener)
    //     }
    // }, [ video.engine ])

    return isFullscreen
}
