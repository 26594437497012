import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'

import { useDrag } from './hooks/useDrag'
import { clamp } from '../../helpers/utils'


interface Props {
    className?: string
    /*
     * value in [0,1]
     */
    value: number
    /*
     * value in [0,1]
     */
    onChange?: (value: number) => void
    widthPx: number
    radiusPx: number
    onDraggingChange?: (dragging: boolean) => void
}

const Container = styled.div<{ $widthPx: number }>`
    display: flex;
    align-items: center;
    width: ${props => props.$widthPx}px;
    height: 2rem;
    cursor: pointer;
`

const ContainerInner = styled.div<{ $heightPx: number, $widthPx: number }>`
    position: relative;
    height: ${props => props.$heightPx}px;
    width: ${props => props.$widthPx}px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BarBackground = styled.div`
    border-radius: 1px;
    position: relative;
    height: 4px;
    width: 100%;
    background: #FFF5;
`

const Ball = styled.div<{ $radiusPx: number }>`
    height: ${props => props.$radiusPx * 2}px;
    width: ${props => props.$radiusPx * 2}px;
    border-radius: ${props => props.$radiusPx}px;
    background: white;
`

const StyledBall = styled(Ball).attrs<{ $leftPx: number }>(({ $leftPx }) => {
    return {
        style: {
            left: $leftPx,
        },
    }
})<{ $leftPx: number }>`
    position: absolute;
    z-index: 1;
`

const BarForeground = styled.div.attrs<{ $percent: number }>(({ $percent }) => {
    return {
        style: {
            width: `${$percent}%`,
        },
    }
})<{ $percent: number }>`
    border-radius: 2px;
    position: absolute;
    background: #FFFF;
    height: 100%;
`

export const BarPicker: React.FC<Props> = (props) => {
    const containerRef = useRef<HTMLDivElement|null>(null)

    const onMouseEvent = useCallback((e: React.MouseEvent | MouseEvent) => {
        if (!containerRef.current) {
            return
        }

        const rect = containerRef.current?.getBoundingClientRect()
        const relativePosition = (e.clientX - rect.left) / rect.width
        const position = clamp(0, 1, relativePosition)

        props.onChange?.(position)
    }, [props])

    const percent = props.value * 100

    const { onMouseDown } = useDrag(onMouseEvent, props.onDraggingChange)
    const { radiusPx, widthPx } = props

    const widthBar = widthPx - (radiusPx * 2)
    const height = radiusPx * 2
    const leftPx = props.value * widthBar

    return (
        <Container
            $widthPx={widthPx}
            onClick={onMouseEvent}
        >
            <ContainerInner
                $heightPx={height}
                $widthPx={widthBar}
            >
                <BarBackground
                    ref={containerRef}
                    className={props.className}
                    onClick={onMouseEvent}
                >
                    <BarForeground $percent={percent} />
                </BarBackground>
                <StyledBall
                    $leftPx={leftPx - radiusPx}
                    $radiusPx={radiusPx}
                    onMouseDown={onMouseDown}
                />
            </ContainerInner>
        </Container>
    )
}

