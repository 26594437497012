import { useEffect, useRef, useState } from 'react'
import { UIState } from '../../constants'

interface Options {
    startTime?: Date
    endTime?: Date
}

const getStateBasedOnTime = (startTime?: Date, endTime?: Date) => {
    if (!startTime) {
        if (endTime) {
            return UIState.AfterEvent
        } else {
            return UIState.Player
        }
    }

    if (!endTime) {
        if (startTime) {
            return UIState.Countdown
        } else {
            return UIState.Player
        }
    }

    const currentTime = new Date()
    if (currentTime < startTime) {
        return UIState.Countdown
    } else if (currentTime >= startTime && currentTime <= endTime) {
        return UIState.Player
    } else {
        return UIState.AfterEvent
    }
}

export const useTimedState = (options: Options) => {
    const { startTime, endTime } = options
    const intervalRef = useRef<number>()
    const [uiState, setUiState] = useState(getStateBasedOnTime(startTime, endTime))

    useEffect(() => {
        const updateState = () => {
            const newState = getStateBasedOnTime(startTime, endTime)
            setUiState(newState)
        }

        intervalRef.current = window.setInterval(updateState, 1000)

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [startTime, endTime])

    return {
        uiState,
    }
}
