import { useState } from "react";

import { isHostedOnWhitelistedDomain, isIframed, isIframeEmbeddedOnWhitelistedDomain } from "../../helpers/whitelist";

export const useIsBlocked = () => {
    const [isBlocked] = useState(() => {
        return !isIframed() || !isHostedOnWhitelistedDomain() || !isIframeEmbeddedOnWhitelistedDomain();
        // return false
    });

    return false;
};
