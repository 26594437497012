import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ $widthValue: string }>`
    width: ${props => props.$widthValue};
    overflow: hidden;
    transition: width 0.2s;
`

const Content = styled.div<{ $widthValue: string }>`
    width: ${props => props.$widthValue};
`

interface Props {
    className?: string
    width: string
    visible: boolean
    children?: React.ReactNode
}

export const SlideHide: FC<Props> = (props) => {
    const { visible, width, className } = props

    return (
        <Container
            $widthValue={visible ? width : '0px'}
            className={className}
        >
            <Content $widthValue={width}>{props.children}</Content>
        </Container>
    )
}
