import styled from 'styled-components'


export const Gradient = styled.div<{ $height: string | number, $isVisible: boolean }>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ $height }) => $height};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    opacity: ${({ $isVisible }) => $isVisible ? 1 : 0};
    transition: all 150ms ease-out;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`
