import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useAtom } from "jotai";

import { PlayerUIState } from "../../constants";
import { Osd } from "../Osd";
import { BigPlayButton } from "../Osd/BigPlayButton";

import { PlayerIntro, PlayerIntroProps } from "./PlayerIntro";
import { useSource } from "./hooks/useSource";
import { VideoPlayer } from "./VideoPlayer";
// import { FullscreenableVideoArea } from './FullScreenableVideoArea'
import { isPlayingAtom, loadSourceErrorAtom, playerControllerAtom } from "./state";
import { FullscreenableVideoArea } from "./FullScreenableVideoArea";
import { analytics } from "../../analytics/Analytics";
import { ErrorOverlay } from "../ErrorOverlay";

const Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    /* background-color: #fff; */
`;

interface Props extends Omit<PlayerIntroProps, "onClick"> {
    channelName: string
    protocol: string
    sourceUrl?: string
}

export const Player = ({ channelName, protocol, sourceUrl, ...rest }: Props) => {
    const [playerState, setPlayerState] = useState(PlayerUIState.Video);
    const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);
    const [playerController] = useAtom(playerControllerAtom);
    const [loadSourceError] = useAtom(loadSourceErrorAtom);
    // const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
        analytics.reportVideoView(channelName);
    }, [channelName]);

    const { backgroundImage } = rest;

    // TODO: Allow after we buffered, but also maybe we should allow rage clicks idk, so the person knows something is happening
    // priority is to start loading segments ASAP for seamless playback experience

    // Show spinner while loading using getSourceUrl, show error when geo block, but nicer than something went wrong
    const handlePlay = useCallback(() => {
        if (playerState === PlayerUIState.Intro) {
            // TODO: Spinner should show, then hide the intro
            setPlayerState(PlayerUIState.Video);
            playerController.seekToLive();
        }
        playerController.unpause();
    }, [playerState, playerController]);

    const handleTogglePlay = useCallback(() => {
        if (isPlaying) {
            playerController.pause();
            setIsPlaying(false);
        } else {
            handlePlay();
            playerController.unpause();
        }
    }, [isPlaying, playerController, setIsPlaying, handlePlay]);

    const { url, isLoading: isLoadingSource, getSourceUrlFromCloud } = useSource({ channelName, protocol, sourceUrl });

    const handleErrorClick = () => {
        getSourceUrlFromCloud()
    }

    const isLoading = isLoadingSource;

    return (
        <Wrapper>
            {/* TODO: Poslat channelId do PlayerIntro aby sa ukazala fallback message s nazvom skupiny */}
            {playerState === PlayerUIState.Intro && <PlayerIntro {...rest} onClick={handlePlay} />}
            {/* <FullscreenableVideoArea>
                {false && url && <VideoPlayer
                    ref={playerControllerRef}
                    url={url!}
                    // poster={backgroundImage}
                    onPause={handlePause}
                    onPlay={handlePlay}
                />}
            </FullscreenableVideoArea> */}
            <FullscreenableVideoArea>
                {(isFullscreen, toggleFullscreen) => (
                    <>
                        {url && (
                            <VideoPlayer
                                url={url!}
                                // poster={backgroundImage}
                            />
                        )}
                        {loadSourceError && !isPlaying &&
                            <ErrorOverlay onButtonClick={handleErrorClick} {...loadSourceError} />
                        }
                        <BigPlayButton isPlaying={isPlaying} isLoading={isLoading} playerState={playerState} onClick={handleTogglePlay} />
                        <Osd
                            isFullscreen={isFullscreen}
                            toggleFullscreen={toggleFullscreen}
                        />
                    </>
                )}
            </FullscreenableVideoArea>
        </Wrapper>
    );
};
