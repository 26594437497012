export function isAutoplayNotAllowedError(error: Error) {
    return error.name === 'NotAllowedError'
}

export function isAbortError(error: Error) {
    return error.name === 'AbortError'
}

export function errorIsError(error: unknown): error is Error {
    return error instanceof Error
}
