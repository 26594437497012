import React, { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { useTimedState } from './useTimedState'
import { UIState } from '../../constants'
import { Player } from '../Player'
import { parseDateStr } from '../../helpers/utils'
import { PlayerCountdownWrapper } from '../PlayerCountdown/PlayerCountdownWrapper'
import { AfterEventCard } from '../AfterEventCard'
import { channelNameAtom } from '../Player/state'

export const StateWrapper = () => {
    const params = useMemo(() => new URLSearchParams(window.location.search), [])
    const [, setChannelName] = useAtom(channelNameAtom)

    const startTimeStr = params.get('startTime')
    const endTimeStr = params.get('endTime')
    const channelName = params.get('channelName') as string
    const sourceUrl = params.get('sourceUrl') as string
    const protocol = params.get('protocol') as string

    const startTime = useMemo(() => parseDateStr(startTimeStr), [startTimeStr])
    const endTime = useMemo(() => parseDateStr(endTimeStr), [endTimeStr])

    useEffect(() => {
        setChannelName(channelName)
    }, [channelName, setChannelName])
    
    const teamsProps = {
        leftFlagImage: params.get('leftFlagImage'),
        nameLeft: params.get('nameLeft'),
        rightFlagImage: params.get('rightFlagImage'),
        nameRight: params.get('nameRight'),
        channelName,
    }

    const commonProps = {
        // TODO: Replace default image with our own
        backgroundImage: params.get('backgroundImage') ?? 'https://storage.googleapis.com/tivio-production-input-admin/organizations%2FZA6ZOtuDD90uHsRkXNyj%2Fvideos%2FCVYC0h2X4KKOqKMoxVwS%2Fcover%2F%402-1727178186774.png',
        ...teamsProps,
    }

    const { uiState } = useTimedState({
        startTime,
        endTime
    })

    return <Player {...commonProps} channelName={channelName} sourceUrl={sourceUrl} protocol={protocol} />
    
    // switch(uiState) {
    //     case UIState.Countdown:
    //         return <PlayerCountdownWrapper startTime={startTime as Date} poster={commonProps.backgroundImage} {...teamsProps} />
    //     case UIState.AfterEvent:
    //         return <AfterEventCard {...commonProps} />
    //     case UIState.Player:
    //     default:
    //         return <Player {...commonProps} channelName={channelName} sourceUrl={sourceUrl} protocol={protocol} />
    // }
}
