import styled, { css } from 'styled-components'


export const ContextMenuContainer = styled.div`
    position: relative;
`

export const ContextMenuWrapper = styled.div`
    position: absolute;
    padding: 8px 0;
    z-index: ${({ theme }) => theme.zIndex.tooltip};
    width: max-content;
    border-radius: 8px;
    background-color: #181A1E;

    ${props => props.theme.breakpoints.down('sm')} {
        max-height: 8rem;
        overflow-y: scroll;
    }
`

export const MenuTitle = styled.div`
    font-weight: 700;
    text-transform: uppercase;
`

export const MenuTitleContainer = styled.div`
    display: flex;
    align-items: center;
    /* margin: 1rem 7rem 1rem 1rem; */
    padding: 1rem;
    padding-bottom: 0;
    gap: 6px;
    cursor: pointer;

    ${props => props.theme.breakpoints.down('sm')} {
        font-size: .75rem;
        margin: 0 3rem 0 1.75rem;
    }
`

export const MenuLabelContainer = styled.div<{ $active?: boolean, $hoverable?: boolean, $condensed?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    box-sizing: border-box;
    gap: 7rem;
    padding: 1.5rem 2.125rem;
    font-weight: 400;
    cursor: pointer;

    ${({ $condensed }) => $condensed && css`
        padding: 1.2rem 2.125rem;
    `}


    ${({ $active }) => $active && css`
        font-weight: 600;
    `}

    ${({ $hoverable = true }) => $hoverable && css`
        &:hover {
            background-color: #2D2F33;
        }
    `}

    &:last-child {
        margin-bottom: 0;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        gap: 1.5rem;
        padding: 1rem 1.5rem;
    }
`

export const LeftMenuLabelContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: .5rem;
`

export const RightMenuLabelContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    opacity: 0.8;
`

export const MenuLabel = styled.div<{ $bold?: boolean}>`
    color: #fff;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;

    ${({ $bold }) => $bold && css`
        font-weight: 600;
    `}
`
