import { useAtom } from 'jotai'
import React from 'react'
import { styled } from 'styled-components'

import { IconButton } from '../IconButton'
import { FullscreenIcon, MinimizeIcon, PauseIcon, PlayIcon, CameraIcon } from '../Icons'
import { isPlayingAtom, playerControllerAtom } from '../Player/state'
import { VolumeControl } from '../volume'

import { SettingsControl } from './SettingsControl'
import { useMenuConfigs } from './SettingsControl/hooks'


const Container = styled.div`
    display: flex;
    justify-content: space-between;
`

const Icons = styled.div`
    display: flex;
    gap: 4px;
`

interface Props {
    isFullscreen: boolean;
    toggleFullscreen: () => void;
}

export const Toolbar = ({ isFullscreen, toggleFullscreen }: Props) => {
    const [isPlaying] = useAtom(isPlayingAtom)
    const [playerController] = useAtom(playerControllerAtom)
    const { subMenuConfigs } = useMenuConfigs()
    return (
        <Container>
            <Icons>
                <IconButton
                    variant="transparent"
                    icon={isPlaying ? PauseIcon : PlayIcon}
                    onClick={playerController.togglePause}
                />
                <VolumeControl />
            </Icons>
            <Icons>
                {/* <IconButton
                    variant="transparent"
                    icon={CameraIcon}
                    onClick={toggleFullscreen}
                /> */}
                <SettingsControl subMenuConfigs={subMenuConfigs} />
                <IconButton
                    variant="transparent"
                    icon={isFullscreen ? MinimizeIcon : FullscreenIcon}
                    onClick={toggleFullscreen}
                />
            </Icons>
        </Container>
    )
}
