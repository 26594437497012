import { getDeviceInfo } from "../helpers/device";
import {
    AliveEvent,
    AnalyticsConversionName,
    AnalyticsConversionStatus,
    AnalyticsEvent,
    AnalyticsEventCustomData,
    AnalyticsEventName,
    LiveAnalyticMetricType,
} from "../types/events";
import { PlayerError } from "../types/shaka";

const ALIVE_EVENT_INTERVAL = 5 * 60 * 1000; // 5 minutes interval in MS
const JOJ_ORGANIZATION_ID = "dEpbY0V54AE34rFO7dB2";
const LOG_EVENT_URL = "https://europe-west3-tivio-production.cloudfunctions.net/logEvent";

const getUserId = () => {
    const userIdFromLocalStorage = localStorage.getItem("tivioUserId");
    if (userIdFromLocalStorage) {
        return userIdFromLocalStorage;
    }
    const randomUserId = Math.random().toString(36).substring(2, 15);
    localStorage.setItem("tivioUserId", randomUserId);
    return randomUserId;
};

const logger = {
    error: console.error,
    info: console.log,
};

class Analytics {
    private _windowEvents: AliveEvent[] = [];
    private _windowTimestamp = Date.now();
    private _userId = getUserId();

    constructor() {
        console.log("Creating Analytics class instance");

        // align reporting to the whole minutes
        if (typeof window !== "undefined") {
            setTimeout(this.startLiveReporting, (60 - new Date().getSeconds()) * 1000);
        }
    }

    get currentWindowTimestamp(): number {
        return Date.now() - this._windowTimestamp;
    }

    get previousWindowEvent() {
        return this._windowEvents[this._windowEvents.length - 1] ?? undefined;
    }

    set currentSourceData(sourceData: Omit<AliveEvent, "timestamp">) {
        this._windowEvents.push({
            ...sourceData,
            timestamp: this.currentWindowTimestamp,
        });
    }

    set currentSourceQuality(quality: number) {
        if (!this.previousWindowEvent) {
            return;
        }

        this._windowEvents.push({
            ...this.previousWindowEvent,
            metric_type: LiveAnalyticMetricType.Quality,
            metric_value: quality,
            timestamp: this.currentWindowTimestamp,
        });
    }

    set currentSourceState(state: string) {
        if (!this.previousWindowEvent) {
            return;
        }

        this._windowEvents.push({
            ...this.previousWindowEvent,
            metric_type: LiveAnalyticMetricType.State,
            metric_value: state,
            timestamp: this.currentWindowTimestamp,
        });
    }

    reportError(error: Error | PlayerError) {
        try {
            // TODO capabilities should be in technology field, not in custom data
            // const capabilities = getDeviceCapabilities()
            // const preferHttp = getCapabilityOptions()?.preferHttp
            // const browserInfo = getBrowserInfo()

            // TODO workaround to avoid circular references when stringifying shaka errors, fix by using something smarter than JSON.stringify
            delete (error as any).currentTarget;
            delete (error as any).target;

            const errorCode = "code" in error ? error.code?.toString() : undefined;
            const errorMessage = "detail" in error ? JSON.stringify({ ...(error.detail ?? {}), timestamp: (error as PlayerError).timeStamp }) : error.message;

            const baseErrorAttributes = {
                error: JSON.stringify(error),
                // tu chcem poslat
                error_message: errorMessage,
                error_severity: "severity" in error ? error.severity : undefined,
                // player_capabilities: JSON.stringify(capabilities),
                // ...(browserInfo && { browser_info: JSON.stringify(browserInfo) }),
                // ...(preferHttp && { prefer_http: JSON.stringify(preferHttp) }),
            };

            if (error instanceof Error || !this.previousWindowEvent) {
                this.reportEvent<AnalyticsEventName.Error>(AnalyticsEventName.Error, {
                    ...baseErrorAttributes,
                    error_code: errorCode ?? "-2",
                });
            } else {
                this.reportEvent<AnalyticsEventName.ErrorPlayer>(AnalyticsEventName.ErrorPlayer, {
                    ...baseErrorAttributes,
                    error_code: errorCode ?? "-1",
                    ...this.previousWindowEvent,
                    metric_type: LiveAnalyticMetricType.Error,
                    metric_value: "-1",
                });
            }
        } catch (error) {
            logger.error("Failed to report error to analytics", error);
        }
    }

    reportAliveEvent = () => {
        logger.info("logging alive events:", this._windowEvents);

        this.reportEvent<AnalyticsEventName.Alive>(AnalyticsEventName.Alive, {
            events: JSON.stringify(this._windowEvents),
        });

        this.clearWindowEvents();
    };

    reportVideoPlay(status: AnalyticsConversionStatus, videoId: string) {
        this.reportEvent<AnalyticsEventName.Conversion>(AnalyticsEventName.Conversion, {
            name: AnalyticsConversionName.PLAY,
            path: window.location.pathname,
            path_param: videoId,
            status,
            target_id: videoId,
            target_type: "element",
            // target_type: AnalyticsConversionTargetBase.Monetization,
        });
    }

    reportVideoView(channelName: string) {
        this.reportEvent(AnalyticsEventName.VideoView, {
            video_id: channelName,
            organization_id: JOJ_ORGANIZATION_ID,
            video_name: channelName,
            season_number: undefined,
            episode_number: undefined,
            // We do not cover use case when user has several subscriptions yet
            user_subscription_id: undefined,
        });
    }

    private clearWindowEvents = () => {
        this._windowTimestamp = Date.now();

        if (!this.previousWindowEvent?.source_id) {
            this._windowEvents = [];
        } else {
            this._windowEvents =
                [...this._windowEvents].reverse().reduce((newWindowEvents: AliveEvent[], event) => {
                    if (!newWindowEvents.length || newWindowEvents[0].source_id === event.source_id) {
                        if (!newWindowEvents.find((newWindowEvent) => newWindowEvent.metric_type === event.metric_type)) {
                            return [
                                ...newWindowEvents,
                                {
                                    ...event,
                                    timestamp: 0,
                                },
                            ];
                        }
                    }

                    return newWindowEvents;
                }, []) ?? [];
        }
    };

    private async reportEvent<T extends AnalyticsEventName>(type: T, customData?: AnalyticsEventCustomData<T>) {
        const { os, version, platform } = getDeviceInfo() ?? {};

        const isNative = platform === "MOBILE" || (platform === "TV" && (os === "iOS" || os === "Android OS"));

        const analyticsEvent: AnalyticsEvent<AnalyticsEventName> = {
            custom_data: customData,
            event_name: type,
            origin: {
                organization_id: JOJ_ORGANIZATION_ID,
                page_location: isNative || typeof window === "undefined" ? undefined : window.location.href,
                page_title: isNative || typeof document === "undefined" ? undefined : document.title,
            },
            technology: {
                bundle_version: "in-stream-payment-demo",
                device_type: platform ?? undefined,
                os,
                os_version: version,
            },
            user: {
                user_id: this._userId,
                user_monetizations: [],
                user_location: {
                    continent: "",
                    country: "",
                    city: "",
                },
                user_profile: undefined,
            },
        };

        /**
        Send event to pub/sub
        */
        fetch(LOG_EVENT_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: analyticsEvent }),
        });
    }

    private startLiveReporting = () => {
        logger.info("startLiveReporting");
        this.clearWindowEvents();

        setInterval(this.reportAliveEvent, ALIVE_EVENT_INTERVAL);
    };
}

const analytics = new Analytics();

export { analytics };
