import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { PlayerController } from '../../types/controller'
import { LangCode } from '../../types/enum'
import { SeekRange } from '../../types/shaka'


export const AUTO_QUALITY_TITLE = 'auto'

export const isBufferingAtom = atom(false)
export const isMutedAtom = atomWithStorage('isPlayerMuted', false)
export const isOsdVisibleAtom = atom(true)
export const isPlayingAtom = atom(false)

export const playerAudioAtom = atom(LangCode.SK)
export const qualitiesAtom = atom<string[]>([])
export const currentQualityAtom = atom<string | null>(null)
export const volumeAtom = atomWithStorage('volume', 1)
export const seekRangeAtom = atom<SeekRange>({
    start: 0,
    end: 0,
})
export const isLiveAtom = atom(true)
export const channelNameAtom = atom<string | null>(null)

export const playerControllerAtom = atom<PlayerController>({
    getIsVideoElementMuted() {
        return false
    },
    unpause() {
        return Promise.resolve()
    },
    pause() {},
    togglePause() {},
    toggleMute() {},
    changeVolume() {},
    setQuality() {},
    goFullScreen() {},
    seekToLive() {},
})

export const loadSourceErrorAtom = atom<{
    title: string,
    message?: string,
    buttonText: string,
} | undefined>(undefined)
