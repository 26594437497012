import { useEffect, useMemo, useState } from 'react'
import { analytics } from '../../../analytics/Analytics';

interface Options {
    sourceUrl?: string;
    channelName?: string;
    protocol?: string;
}

interface Response {
    result: {
        url: string
        sourceHistory: string[]
        sourcePlayMode: 'LIVE'
    }
    playlists: {
        dash: string
        hls: string
    }
}

const channelNameToId = {
    'sport1': 'eV0yswdHcCFbcxw6KFct',
    'sport2': '98IcNE6GgyDyV29F6rW5',
    'joj': 'LYyAwEjjqmj8kMY23Lqw',
    'plus': '60K9GwR6CLApIHVyNYOj',
}

const getSource = async (method: RequestInit["method"] = 'GET', payload?: any, abortController?: AbortController) => {
    const resultJSON = await fetch('https://management.tivio.studio/live/fm8415v3a64bfTCZ0Ti3/qWEPQQBdhgIveDSk4V47/user/3yjt38jTfupasgEvVhgd', {
        ...(abortController ? { signal: abortController.signal } : {}),
        method,
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })

    const data: Response = await resultJSON.json()
    return data
}

export const useSource = (options: Options) => {
    const { sourceUrl, channelName, protocol } = options
    const [localSourceUrl, setLocalSourceUrl] = useState<string | undefined>(sourceUrl)
    const [url, setUrl] = useState<string | undefined>(sourceUrl?.startsWith('http') ? sourceUrl : undefined)
    const [isLoading, setIsLoading] = useState(false)

    const channelId = useMemo(() => channelName ? channelNameToId[channelName as keyof typeof channelNameToId] : undefined, [channelName])

    const getSourceUrlFromCloud = () => setLocalSourceUrl(undefined)

    const changeActiveVideo = async (activeVideoId: string) => {
        setIsLoading(true)
        const data = await getSource('PATCH', {
            "metadata": {
                "activeVideoId": activeVideoId,
            }
        })
        setUrl(data.playlists.hls)
        setLocalSourceUrl(data.playlists.hls)
        setIsLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()

        const init = async () => {
            try {
                setIsLoading(true)
                const data = await getSource('POST', {
                    "metadata": {
                        "currency": "eur",
                        "price": "1",
                        "tastingPeriod": 50,
                        "activeVideoId": "qQoAmUurFBNEGiSwG0xf",
                        "videoIds": [
                            "qQoAmUurFBNEGiSwG0xf",
                            "a5jBWa46qfd1bE9OGJPp",
                            "PcjKYgUQd2sKtgdllxHN",
                            "qvvhcKYcXxlQM3kNm1Kk",
                            "WyjS3BQ4wlmwpclPrfz9"
                        ]
                    }
                }, abortController)
                setUrl(data.playlists.hls)
                setLocalSourceUrl(data.playlists.hls)
                setIsLoading(false)
            } catch (err) {
                console.error('error fetching url', err)
                analytics.reportError({
                    ...(err as Error),
                    message: (err as Error).message ?? 'fetch failed',
                    name: (err as Error).name ?? 'useSource',
                })
            }
        }

        init()
        // if (!localSourceUrl) {
        //     if (!channelId) {
        //         console.error('Channel ID not source URL is set')
        //         analytics.reportError({
        //             message: 'Channel ID not source URL is set',
        //             name: 'useSource',
        //         })
        //         return
        //     }
        //     init()
        // }

        return () => {
            abortController.abort()
        }
    }, [channelId, localSourceUrl, protocol])

    return {
        url,
        isLoading,
        getSourceUrlFromCloud,
        changeActiveVideo
    }
}
