import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'

import { SlideHide } from './SlideHide'
import { VolumePicker } from './VolumePicker'

import { isMutedAtom, playerControllerAtom, volumeAtom } from '../Player/state'
import { MutedIcon, VolumeHighIcon, VolumeLowIcon } from '../Icons'
import { IconButton } from '../IconButton'
import { isMobileBrowser } from '../../helpers/utils'


const Container = styled.div`
    display: flex;
    /* height: 32px; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const isAudible = (volume: number) => {
    return volume > 0.01
}

/**
 * @param volume in range [0,1]
 */
const getIcon = (volume: number, isMuted: boolean) => {
    if (isMuted || !isAudible(volume)) {
        return MutedIcon
    }

    if (volume > 0.5) {
        return VolumeHighIcon
    } else {
        return VolumeLowIcon
    }
}

export const VolumeControl = () => {
    const {
        isAudible,
        isMuted,
        toggleMute,
        volume,
    } = useVolumeHelpers()

    const {
        isPickerVisible,
        onDraggingChange,
        setIsHovered,
        unsetIsHovered,
    } = usePickerVisibilityHelpers()

    const width = 90

    return (
        <Container
            onMouseEnter={setIsHovered}
            onMouseLeave={unsetIsHovered}
        >
            <IconButton
                icon={getIcon(volume ?? 1, isMuted)}
                variant="transparent"
                title={isMuted || !isAudible ? 'Zapnúť zvuk' : 'Stlmiť zvuk'}
                onClick={() => toggleMute()}
            />
            <SlideHide
                width={`${width + 2}px`}
                visible={isPickerVisible}
            >
                <VolumePicker
                    widthPx={width}
                    radiusPx={7}
                    onDraggingChange={onDraggingChange}
                />
            </SlideHide>
        </Container>
    )
}

const useVolumeHelpers = () => {
    const [isMuted] = useAtom(isMutedAtom)
    const [volume] = useAtom(volumeAtom)
    const [playerController] = useAtom(playerControllerAtom)

    return {
        isAudible: isAudible(volume ?? 1),
        isMuted,
        toggleMute: playerController.toggleMute,
        volume,
    }
}

const usePickerVisibilityHelpers = () => {
    const [isPickerVisible, setIsPickerVisible] = useState(false)
    const isHoveredRef = useRef(false)
    const isDraggingRef = useRef(false)

    const maybeHidePicker = () => {
        if (!isHoveredRef.current && !isDraggingRef.current) {
            setIsPickerVisible(false)
        }
    }

    const setIsHovered = useCallback(() => {
        if (isMobileBrowser()) {
            // iPhone does no support volume change via javascript
            // https://stackoverflow.com/questions/9752983/setvolume-for-html5-audio-doesnt-work-on-mobile-android-or-safari-any-workaro
            return
        }

        isHoveredRef.current = true
        setIsPickerVisible(true)
    }, [])

    const unsetIsHovered = useCallback(() => {
        if (isMobileBrowser()) {
            return
        }

        isHoveredRef.current = false
        maybeHidePicker()
    }, [])

    const onDraggingChange = useCallback((is: boolean) => {
        isDraggingRef.current = is

        if (!is) {
            maybeHidePicker()
        }
    }, [])

    return {
        isPickerVisible,
        onDraggingChange,
        setIsHovered,
        unsetIsHovered,
    }
}
