import { useEffect, useState } from 'react'

import type { RefObject } from 'react'


export const useIsFullScreen = (elementRef: RefObject<HTMLElement | null>) => {
    const [isFullscreen, setIsFullscreen] = useState(false)

    useEffect(() => {
        const listener = () => {
            setIsFullscreen(Boolean(
                (document.fullscreenElement != null && document.fullscreenElement === elementRef.current)
                // @ts-expect-error Safari
                || (document.webkitFullscreenElement != null && document.webkitFullscreenElement === elementRef.current),
            ))
        }

        document.addEventListener('fullscreenchange', listener)
        // Safari
        document.addEventListener('webkitfullscreenchange', listener)
        return () => {
            document.removeEventListener('fullscreenchange', listener)
            // Safari
            document.removeEventListener('webkitfullscreenchange', listener)
        }
    }, [])

    return isFullscreen
}

/**
 * Because on mobile devices fullscreen API because available only since 2021
 * https://caniuse.com/?search=requestFullScreen
 */
export const getRequestFullScreenMethod = (element: HTMLElement) => {
    const method = element.requestFullscreen
        // @ts-expect-error
        ?? element.webkitRequestFullScreen
        // @ts-expect-error
        ?? element.mozRequestFullScreen
        // @ts-expect-error
        ?? element.msRequestFullscreen

    if (method) {
        return method.bind(element, { navigationUI: 'hide' })
    } else {
        return null
    }
}

type ExitMethod = () => null | (() => Promise<void>)

/**
 * Because on mobile devices fullscreen API because available only since 2021
 * https://caniuse.com/?search=requestFullScreen
 */
export const getExistFullScreenMethod: ExitMethod = () => {
    const method = document.exitFullscreen
        // @ts-expect-error
        ?? document.msExitFullscreen
        // @ts-expect-error
        ?? document.mozCancelFullScreen
        // @ts-expect-error
        ?? document.webkitExitFullscreen

    if (method) {
        return method.bind(document)
    } else {
        return null
    }
}

export const isFullScreenSupported = (element: HTMLElement) => Boolean(getRequestFullScreenMethod(element)
    && getExistFullScreenMethod())
