import { createIcon } from './createIcon'


export const PlayIcon = createIcon({
    d: 'M5.33752 1.86812C5.34549 1.87344 5.35348 1.87877 5.3615 1.88411L12.394 6.57245C12.5975 6.70808 12.7862 6.83383 12.931 6.95068C13.0823 7.07264 13.2605 7.24175 13.3631 7.48916C13.4987 7.81616 13.4987 8.18367 13.3631 8.51068C13.2605 8.75808 13.0823 8.9272 12.931 9.04915C12.7862 9.166 12.5975 9.29175 12.394 9.42737L5.33754 14.1317C5.08882 14.2975 4.86516 14.4467 4.67539 14.5495C4.48548 14.6524 4.2248 14.77 3.92055 14.7519C3.53139 14.7286 3.17188 14.5362 2.93668 14.2253C2.75279 13.9822 2.70605 13.7001 2.68634 13.485C2.66665 13.2701 2.66667 13.0013 2.66669 12.7023L2.66669 3.32633C2.66669 3.31669 2.66669 3.30708 2.66669 3.29751C2.66667 2.99857 2.66665 2.72978 2.68634 2.51483C2.70605 2.29972 2.75279 2.01759 2.93668 1.77452C3.17188 1.4636 3.53139 1.2712 3.92055 1.24796C4.2248 1.2298 4.48548 1.34741 4.67539 1.45033C4.86516 1.55317 5.08881 1.70229 5.33752 1.86812ZM4.01677 2.61022C4.01587 2.6183 4.01498 2.62704 4.01412 2.63648C4.00069 2.78298 4.00002 2.98937 4.00002 3.32633V12.6735C4.00002 13.0105 4.00069 13.2169 4.01412 13.3633C4.01498 13.3728 4.01587 13.3815 4.01677 13.3896C4.02399 13.3859 4.03176 13.3818 4.0401 13.3773C4.16943 13.3072 4.34153 13.1932 4.6219 13.0063L11.6323 8.33274C11.8655 8.17726 12.0022 8.08533 12.094 8.01128C12.099 8.00727 12.1036 8.00348 12.1079 7.99992C12.1036 7.99635 12.099 7.99256 12.094 7.98855C12.0022 7.9145 11.8655 7.82257 11.6323 7.6671L4.6219 2.99351C4.34153 2.8066 4.16943 2.69267 4.0401 2.62258C4.03176 2.61806 4.02399 2.61395 4.01677 2.61022Z',
    defaultProps: {
        size: 16,
        color: 'currentColor',
    },
    displayName: 'PlayIcon',
    viewBox: '0 0 16 16',
})
